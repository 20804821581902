<template>
  <div v-if="!order.job.sfid" class="py-4">
    {{ order.job.api_id }} / {{ order.opportunity.name }} -
    <em class="text-muted">{{ $t('order-processing') }}</em>
  </div>
  <b-row v-else class="py-4">
    <b-col
      v-if="hasAccountColumn"
      :lg="ordersGrid.account.lg"
      class="my-1 my-lg-0"
    >
      <account-display :accountId="order.partner.id"></account-display>
    </b-col>
    <b-col :lg="ordersGrid.order.lg" class="my-1 my-lg-0">
      {{ order.job.name }} / {{ order.opportunity.name }}
      <span v-if="!isInstaller">
        (
        <!-- not visible until fixed with https://app.asana.com/0/772623105799740/1201570195359250
      <s
        class="text-muted mr-1"
        v-if="firstCancelationWithPriceChange"
      >{{ firstCancelationWithPriceChange.old_price_purchasing | currency }}</s>
      -->
        {{ pricePurchasingForPartner | currency }}
        )
      </span>
      <div class="d-flex flex-column text-muted">
        <span
          :id="`project-id-${order.sfid}`"
          class="gid-label"
          @click="copy($event, order.project.name)"
        >
          {{ $t('project-id') }}: {{ order.project.name }}
        </span>
        <b-tooltip :target="`project-id-${order.sfid}`" triggers="hover">
          {{ order.project.name }}
        </b-tooltip>

        <span v-if="order.job.eid">{{ order.job.eid }}</span>
        <span v-if="order.job.invoicing_eid">
          {{ order.job.invoicing_eid }}
        </span>
      </div>
      <b-badge variant="secondary" v-if="isPendingApproval">
        {{ $t('proposal.pending-approval') }}
      </b-badge>
      <b-badge v-if="order.is_remote">{{ $t('remote-job') }}</b-badge>
      <b-badge v-if="order.is_precheck">{{ $t('precheck-job') }}</b-badge>
      <b-badge v-if="order.is_activation">{{ $t('activation-job') }}</b-badge>
    </b-col>
    <b-col :lg="2">
      <OrderEid :order="order" :allowPartnerEidEdit="allowPartnerEidEdit" />
    </b-col>
    <b-col class="my-1 my-lg-0" :lg="2">
      <span v-if="order.job.appointment_start_timestamp">
        <span class="d-lg-none">{{ $t('on') }}</span>
        <strong>
          {{ order.job.appointment_start_timestamp | moment('dd, L') }}
          {{ order.job.appointment_start_timestamp | moment('H:mm') }} -
          {{ order.job.appointment_end_timestamp | moment('H:mm') }}
        </strong>
      </span>
      <span v-else-if="isPendingApproval">
        {{ $t('proposal.pending-approval') }}
      </span>
      <span v-else-if="isCancelled">{{ $t('proposal.cancelled-order') }}</span>
      <span v-else-if="waiting">
        {{ $t('not-ready-for-work') }}
        <span
          :id="`${order.api_id}-tooltip-target-not-scheduled`"
          tabindex="0"
          class="mx-2 icon-pointer no-outline"
        >
          <i class="material-icons">help_outline</i>
        </span>
      </span>
      <div class="d-flex flex-column" v-else-if="appointmentNotScheduled">
        {{ scheduleAppointmentAfter }}
        <div v-if="type == 'open'">
          <a
            href="#"
            v-b-modal:[`customer-unreachable-${order.sfid}`]
            @click.prevent
          >
            {{ $t('customer-unreachable') }}?
          </a>
        </div>
      </div>
      <span v-else>
        <div v-if="type == 'open'">
          <div>{{ $t('not-scheduled') }}</div>
          <a
            href="#"
            v-b-modal:[`customer-unreachable-${order.sfid}`]
            @click.prevent
          >
            {{ $t('customer-unreachable') }}?
          </a>
        </div>
      </span>
      <b-tooltip
        v-if="waiting"
        :target="`${order.api_id}-tooltip-target-not-scheduled`"
        trigger="hover"
      >
        <p>{{ $t('not-ready-tooltip.why') }}</p>
        <ul class="text-left">
          <li>
            {{ $t('not-ready-tooltip.more-info') }}
          </li>
          <li>
            {{ $t('not-ready-tooltip.products') }}
          </li>
        </ul>
      </b-tooltip>
      <div v-if="order.delegated_to.email" class="text-muted mt-2">
        {{ $t('orders-list.delegated-to') }}:
        {{ order.delegated_to.first_name }} {{ order.delegated_to.last_name }}
      </div>
    </b-col>
    <b-col class="my-1 my-lg-0" :lg="2">
      <span class="d-lg-none">{{ $t('at') }}</span>
      <a
        :href="`https://maps.google.com/?q=${order.customer.shipping_address.street} ${order.customer.shipping_address.city}+${order.customer.shipping_address.postal_code}`"
        target="_blank"
      >
        {{ order.customer.name }},
        <br />
        {{ order.customer.shipping_address.street }},
        <br />
        <template v-if="order.customer.company_name">
          {{ order.customer.company_name }},
          <br />
        </template>
        {{ order.customer.shipping_address.postal_code }}
        {{ order.customer.shipping_address.city }}
      </a>
      <div
        v-if="
          order.job_contact_person.first_name && item && item.job_contact_person
        "
        class="mb-0"
      >
        <p class="mb-0">
          <strong>{{ $t('contact-person.title') }}:</strong>
        </p>
        <p class="my-0">
          {{ item.job_contact_person.first_name }}
          {{ item.job_contact_person.last_name }}
        </p>
      </div>
      <div class="mt-0" v-if="hasPhone">
        Tel:
        <span class="d-sm-inline d-md-none">
          {{ contactPhone }}
          <a
            :href="`tel:${contactPhone}`"
            role="button"
            class="material-icons text-decoration-none bg-primary text-white p-2 rounded d-sm-inline d-md-none"
            @click="contactCustomer()"
            v-if="hasPhone && isInType"
            v-b-tooltip.hover
            title="Customer"
          >
            phone
          </a>
        </span>
        <a
          class="d-none d-md-inline"
          :href="`tel:${contactPhone}`"
          @click="contactCustomer()"
        >
          {{ contactPhone }}
        </a>
      </div>
    </b-col>
    <b-col
      :lg="ordersGrid.status.lg"
      v-if="ordersGrid.status"
      class="my-1 my-lg-0"
    >
      {{
        greaterDateFormatted(order.job.readiness_date, order.job.date_expected)
      }}
      <br />
      <strong>
        {{ latestStatusChangeFormatted(order) }}
        {{ order.status_description.partner_name[locale] }}
      </strong>
      <template v-if="!past && !waiting">
        <DaysSinceLastChange :days="order.days_since_last_status_change" />
      </template>
    </b-col>
    <b-col :lg="ordersGrid.action.lg" class="my-1 my-lg-0">
      <template v-if="past">
        <p>
          {{ status }}
          <span v-if="past && order.job.date_end">
            <br />
            {{ order.job.date_end | moment('lll') }}
          </span>
          <template v-if="order.invoices && order.invoices.length">
            <template v-for="(invoiceView, idx) in order.invoices">
              <span :key="idx" v-if="invoiceView.invoice.pdf_sfid">
                <br />
                {{ $t('invoice') }}:
                <a
                  :href="invoiceUrl(invoiceView.invoice.pdf_sfid)"
                  target="blank"
                >
                  <strong>{{ invoiceView.name }}</strong>
                </a>
                / {{ $t(`invoice-statuses.${invoiceView.invoice.status}`) }}
              </span>
            </template>
          </template>
        </p>
      </template>
      <b-button
        v-if="
          (!canDelegate(order) && !canCreateProposal) ||
          past ||
          customerSignature
        "
        variant="primary"
        block
        :to="detailsPath"
      >
        {{ $t('details') }}
      </b-button>
      <template v-else>
        <b-dropdown
          variant="outline-primary"
          block
          split
          :text="$t('details')"
          :split-to="detailsPath"
          split-variant="primary"
        >
          <b-dropdown-item v-if="canDelegate(order)" @click="$emit('delegate')">
            {{ $t('delegate.title') }}...
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canCreateProposal"
            :to="{ name: 'create', params: { job_id: order.sfid } }"
          >
            {{ $t('proposal.create') }}...
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <b-button
        v-if="type == 'waiting-action'"
        class="mt-2"
        variant="danger"
        block
        :to="{ ...detailsPath, query: { showActions: true } }"
      >
        {{ $t('take-action') }}
      </b-button>
    </b-col>
    <CustomerUnreachableModal :order="order" />
  </b-row>
</template>

<script>
import { ContactProfileEnum, JobStatusEnum, UserRoleEnum } from '@gid/models';
import { mapState, mapGetters } from 'vuex';
import { checkRouteAccess } from '@gid/vue-common/store/auth.module';
import PriceHasChanged from '@gid/vue-common/components/mixins/PriceHasChanged.js';
import AccountDisplay from '@/components/AccountDisplay.vue';
import OrderEid from '@/components/orders/OrderEidAddEdit';
import DaysSinceLastChange from '@gid/vue-common/components/DaysSinceLastChange';
import OrderInListMixin from './OrderInListMixin';
import CanDelegateOrder from './CanDelegateOrderMixin';
import axios from 'axios';
import { CommentActionTypeEnum } from '@gid/models/dist/entities/comment';
import CustomerUnreachableModal from './CustomerUnreachableModal.vue';

export default {
  mixins: [PriceHasChanged, OrderInListMixin, CanDelegateOrder],
  components: {
    AccountDisplay,
    DaysSinceLastChange,
    CustomerUnreachableModal,
    OrderEid,
  },
  props: {
    order: Object,
    type: String,
    statuses: Object,
    displayAccount: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    allowPartnerEidEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showPartnerEidEditForm: null,
      partnerExternalEid: '',
      showPartnerLoader: false,
    };
  },
  computed: {
    ...mapGetters([
      'access_token',
      'locale',
      'user',
      'isAuthenticated',
      'contactProfiles',
    ]),
    ...mapState({
      isInstaller: (state) =>
        state.auth.user.contact.profiles.includes(ContactProfileEnum.INSTALLER),
    }),
    isInType() {
      return ['open', 'at-work', 'scheduled'].includes(this.type);
    },
    hasPhone() {
      return (
        this.order.customer.phone ||
        this.order.customer.mobile_phone ||
        this.order?.job_contact_person?.phone
      );
    },
    contactPhone() {
      if (
        this.order.job.job_contact_person &&
        this.order?.job_contact_person?.first_name
      ) {
        return this.order?.job_contact_person?.phone;
      }
      return this.order.customer.mobile_phone
        ? this.order.customer.mobile_phone
        : this.order.customer.phone;
    },
    isInstaller() {
      return (
        this.contactProfiles.includes('installer') &&
        this.contactProfiles.length === 1
      );
    },
    status() {
      return this.statuses
        ? this.statuses[this.order.job.status]
        : this.order.job.status;
    },
    accessToken() {
      return this.access_token();
    },
    canCreateProposal() {
      if (
        this.order.opportunity.can_create_proposal?.includes('partner') &&
        this.order.project.id &&
        this.order.sequence_index == 1
      ) {
        const resolved = this.$router.resolve({ name: 'create' });
        return (
          resolved &&
          checkRouteAccess({
            route: resolved.route,
            user: this.user,
            isAuthenticated: this.isAuthenticated,
          })
        );
      }
      return false;
    },
    detailsPath() {
      if (this.past) {
        return { name: 'order-complete', params: { job_id: this.order.sfid } };
      } else if (this.finalConfirmation) {
        return {
          name: 'order-final-confirmation',
          params: { type: this.type, job_id: this.order.sfid },
        };
      } else if (this.customerSignature) {
        return {
          name: 'order-customer-signature',
          params: { type: this.type, job_id: this.order.sfid },
        };
      }
      return {
        name: 'order',
        params: { type: this.type, job_id: this.order.sfid },
      };
    },
    isPendingApproval() {
      return [JobStatusEnum.PROPOSAL_APPROVAL_PENDING].includes(
        this.order.job.status,
      );
    },
    appointmentNotScheduled() {
      const readinessOrDeliveryDate =
        this.order.job.readiness_date || this.order.job.date_expected;
      return (
        readinessOrDeliveryDate &&
        (new Date(this.order.job.readiness_date) > new Date() ||
          new Date(this.order.job.date_expected) > new Date()) &&
        !this.order.job.appointment_start_timestamp
      );
    },
    scheduleAppointmentAfter() {
      if (
        this.$moment(this.order.job.readiness_date).isAfter(
          this.$moment(this.order.job.date_expected),
        ) ||
        !this.order.job.date_expected
      ) {
        return this.$t('order-details.appointment-after', {
          date: this.$moment(this.order.job.readiness_date).format('L'),
        });
      }
      if (
        this.$moment(this.order.job.date_expected).isAfter(
          this.$moment(this.order.job.readiness_date),
        ) ||
        !this.order.job.readiness_date
      ) {
        return this.$t('order-details.appointment-after', {
          date: this.$moment(this.order.job.date_expected).format('L'),
        });
      }

      return null;
    },
    isCancelled() {
      return ['cancelled', 'closed'].includes(this.order.job.status);
    },
    pricePurchasingForPartner() {
      return [
        ...this.order.services,
        ...this.order.additional_items,
        ...this.order.custom_services,
      ]
        .filter((el) => el.price_purchasing != null)
        .reduce((acc, el) => acc + el.quantity * el.price_purchasing, 0);
    },
    showAddPartnerButton() {
      return (job) =>
        this.allowPartnerEidEdit &&
        !job?.partner_eid &&
        !this.showPartnerEidEditForm;
    },
    showPartnerEdit() {
      return (job) => {
        return (
          this.allowPartnerEidEdit &&
          job?.partner_eid &&
          !this.showPartnerEidEditForm
        );
      };
    },
  },
  methods: {
    async copy(event, value) {
      event.stopPropagation();

      if (location.protocol === 'https:') {
        await navigator.clipboard.writeText(value);
      } else {
        const el = document.createElement('textarea');
        el.value = value;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    },
    contactCustomer() {
      this.createComment(this.order.job.api_id, this.$t('call.customer'), [
        UserRoleEnum.ADMIN,
        UserRoleEnum.PARTNER,
        UserRoleEnum.BRAND,
      ]);
    },
    createComment(jobApiId, message, visibleFor) {
      const commentData = {
        actionType: CommentActionTypeEnum.PHONE,
        jobApiId,
        message,
        visibleForRoles: visibleFor,
      };
      axios.post(`/data-api/comment`, commentData);
    },
    invoiceUrl(invoiceId) {
      return `/api/invoice/download/${invoiceId}?jwt=${this.accessToken}`;
    },
    getPriceChangeConfig() {
      return {
        job_view: this.order,
        price_purchasing: true,
      };
    },
    greaterDateFormatted(a, b) {
      const date = a && b ? (a > b ? a : b) : a ? a : b;
      return date
        ? this.$t('job.readyforinstallation', {
            installationdate: this.$moment(date).format('L'),
          })
        : '-';
    },
    latestStatusChangeFormatted(item) {
      return item.job.last_status_change
        ? this.$t('job.statuschange', {
            status_change_date: this.$moment(
              item.job.last_status_change,
            ).format('L'),
          })
        : '';
    },
  },
};
</script>
<style scoped>
.gid-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
