<template>
  <div v-if="order && !order.is_remote">
    <h2 class="mt-3">
      {{ order.opportunity && order.opportunity.name }}
    </h2>
    <h5 class="mt-2 mb-4">{{ order.job.name }}</h5>
    <b-row class="border-bottom py-3">
      <b-col cols="12" lg="3" class="text-muted">{{ $t('address') }}:</b-col>
      <b-col cols="12" lg="9">
        <a
          :href="`https://maps.google.com/?q=${order.customer.shipping_address.street}+${order.customer.shipping_address.city}+${order.customer.shipping_address.postal_code}`"
          target="_blank"
        >
          {{ order.customer.name }},
          <br />
          {{ order.customer.shipping_address.street }},
          <br />
          <template v-if="order.customer.company_name">
            {{ order.customer.company_name }},
            <br />
          </template>
          {{ order.customer.shipping_address.postal_code }}
          {{ order.customer.shipping_address.city }}
        </a>
        <div v-if="order.job_contact_person.first_name" class="mb-0">
          <p class="mb-0">
            <strong>{{ $t('contact-person.title') }}:</strong>
          </p>
          <p class="my-0">
            {{
              `${order.job_contact_person.first_name} ${order.job_contact_person.last_name}`
            }}
          </p>
          <br />
        </div>
      </b-col>
    </b-row>
    <template v-if="isCustomerSignatureMissing">
      <b-row class="border-bottom py-3">
        <b-col cols="12" lg="3" class="text-muted">
          {{ $t('inputs.headings.customer-signature-timestamp') }}:
        </b-col>
        <b-col cols="12" lg="3">
          <p class="m-0">{{ new Date() | moment('DD MMM YYYY, HH:mm') }}</p>
        </b-col>
      </b-row>
      <input-holder
        v-if="Object.keys(orderIntakeInputExtra).length > 0"
        input-name="order-intake-protocol"
        type="order-intake-forms"
      >
        <order-intake-forms
          type="order-intake-forms"
          input-name="order-intake-protocol"
          :elementId="this.order.job.sfid"
          :opportunitySfid="this.order.opportunity.sfid"
          :orderApiId="this.order.api_id"
          :accessToken="this.access_token"
          @redirect="orderIntakeFormsRedirect"
          v-bind.sync="orderIntakeInputExtra"
        />
      </input-holder>
    </template>
    <b-icon
      v-else
      icon="check2-circle"
      variant="primary"
      class="success-icon"
    ></b-icon>
  </div>
  <not-found v-else-if="order === false" />
</template>

<script>
/* eslint-disable vue/no-unused-components */
// eslint can't detect the dynamic usage of the components

import axios from 'axios';
import { mapGetters } from 'vuex';
import OrderIntakeForms from '@gid/vue-common/components/OrderIntakeForms';
import {
  CustomerSignatureStatusEnum,
  FormOrderModificationCategoryEnum,
} from '@gid/models';
import { JobTypeEnum } from '@gid/models/dist/entities/job-type-enum';
import NotFound from '@/views/NotFound.vue';
import InputHolder from './InputHolder.vue';
import { BIcon, BIconCheck2Circle } from 'bootstrap-vue';

export default {
  components: {
    NotFound,
    InputHolder,
    OrderIntakeForms,
    BIcon,
    BIconCheck2Circle,
  },
  props: {
    job_id: {
      type: String,
      required: true,
    },
  },
  watch: {
    job_id() {
      this.loadData();
    },
    order(newValue, oldValue) {
      if (newValue) {
        this.loadOrderIntakeExtra();
      }
    },
  },
  data() {
    return {
      order: null,
      orderIntakeInputExtra: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['locale', 'access_token']),
    isCustomerSignatureMissing() {
      return (
        this.order.job.customer_signature ===
        CustomerSignatureStatusEnum.MISSING
      );
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    redirect() {
      const redirect = this.$router.currentRoute?.query?.redirect;
      const name = this.$router.currentRoute?.query?.name;
      const type = this.$router.currentRoute?.query?.type;
      if (redirect && name && type) {
        this.$router.push({
          name,
          params: { type, job_id: this.order.sfid },
        });
      } else {
        this.$router.push({
          name: 'orders-customer-signature',
        });
      }
    },
    loadData() {
      this.order = null;
      axios
        .get(`/api/partner/orders/${this.job_id}`)
        .then((response) => {
          this.order = response.data;
          this.$store.commit(
            'title',
            this.$t('order-with-number', { num: this.order.job.name }),
          );
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            this.order = false;
          }
        });
    },
    async loadOrderIntakeExtra() {
      if (this.order) {
        const formType = this.getFormTypeFromJobType();
        if (!formType) {
          return;
        }
        const extra = {};
        const customerFormUrl = `/order-intake-forms-api/form/${this.order.opportunity.sfid}/form-type/${formType}/protocol-type/default/category/${FormOrderModificationCategoryEnum.CONFIRMATION}`;
        let customerForm = null;
        try {
          const customerFormResponse = await axios.get(customerFormUrl);
          customerForm = customerFormResponse.data;
          if (customerForm) {
            extra.locale = customerForm.locale;
            extra.form = customerForm.id;
            extra.theme = customerForm.theme;
          }
        } catch (err) {
          console.error(err);
        }
        this.orderIntakeInputExtra = extra;
      }
    },
    getFormTypeFromJobType() {
      switch (true) {
        case this.order.is_precheck:
          return JobTypeEnum.PRECHECK;
        case this.order.is_installation:
          return JobTypeEnum.INSTALLATION;
        case this.order.is_meter_application:
          return JobTypeEnum.METER_APPLICATION;
        case this.order.is_installation_preparation:
          return JobTypeEnum.INSTALLATION_PREPARATION;
        case this.order.is_maintenance:
          return JobTypeEnum.MAINTENANCE;
        case this.order.is_defect_elimination:
          return JobTypeEnum.DEFECT_ELIMINATION;
        case this.order.is_hardware_sale:
          return JobTypeEnum.HARDWARE_SALE;
        default:
          return null;
      }
    },
    orderIntakeFormsRedirect(data) {
      this.redirect();
    },
  },
};
</script>

<style lang="scss">
.b-icon.bi.success-icon {
  font-size: 16rem;
  padding: 2rem;
  display: block;
  margin: 0 auto;
}

.action {
  display: flex;
}
</style>
