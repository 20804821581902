<template>
  <b-dropdown
    v-if="user"
    dropup
    right
    variant="light"
    class="auth-dropup"
    toggle-class="auth-dropup-toggle"
  >
    <template #button-content>
      <div class="auth-dropup-toggle-text">
        <span>{{ accountFormattedName }}</span>
        <span v-if="accounts?.length > 1 && accountCity">
          {{ accountCity }}
        </span>
      </div>
    </template>
    <b-dropdown-item
      v-if="isPartnerAdmin || isCompanyOwner"
      :to="{ name: 'company' }"
      link-class="auth-dropup-item"
    >
      <b-icon-building />
      {{ $t('company-page.company') }}
    </b-dropdown-item>
    <b-dropdown-item
      href="https://www.getitdone.rocks/service-partner-agb"
      target="_blank"
      link-class="auth-dropup-item"
    >
      <b-icon-file-earmark-text />
      {{ $t('tandc.heading') }}
    </b-dropdown-item>
    <b-dropdown-item
      v-if="accounts?.length > 1"
      v-b-modal.change-account
      link-class="auth-dropup-item"
    >
      <b-icon-geo-alt />
      {{ $t('change-account') }}
    </b-dropdown-item>
    <b-dropdown-item @click="logout" link-class="auth-dropup-item">
      <b-icon-box-arrow-right />
      Logout
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {
  BIconBuilding,
  BIconBoxArrowRight,
  BIconGeoAlt,
  BIconFileEarmarkText,
} from 'bootstrap-vue';
import { mapState } from 'vuex';
import { PURGE_AUTH } from '@gid/vue-common/store/auth.module/types';
import { ContactProfileEnum } from '@gid/models';

export default {
  components: {
    BIconBuilding,
    BIconBoxArrowRight,
    BIconGeoAlt,
    BIconFileEarmarkText,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      accounts: (state) => state.auth.accounts,
    }),
    accountCity() {
      return (
        this.user.account.shipping_address.city ||
        this.user.account.billing_address.city ||
        null
      );
    },
    accountFormattedName() {
      return this.user.account.name.replace(/\(Standort:.*?\)/g, '').trim();
    },
    isPartnerAdmin() {
      return this.user?.profiles?.includes(ContactProfileEnum.ADMIN);
    },
    isCompanyOwner() {
      return this.user?.profiles?.includes(ContactProfileEnum.COMPANY_OWNER);
    },
  },
  methods: {
    logout() {
      this.$store.commit(PURGE_AUTH);
    },
  },
};
</script>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@gid/vue-common/scss/_variables.scss';

.auth-dropup {
  z-index: 1031;

  .auth-dropup-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    .auth-dropup-toggle-text {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      white-space: normal;
      text-align: left;

      > :nth-child(2) {
        color: $text-muted;
        font-size: 0.9rem;
      }
    }
  }
  .auth-dropup-item {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    padding: 0.5rem 1rem;

    &:hover {
      color: $primary;
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }
  }
}
</style>
